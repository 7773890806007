import React from "react";
import styled from "styled-components";

const calcHeight = width => props => {
  if (props.noHeight) {
    return "";
  }

  if (props.ratio) {
    return `height: ${width * props.ratio}px`;
  }

  if (props.extraHeight) {
    return `height: ${width + props.extraHeight}px`;
  }

  return `height: ${width}px`;
};

export default styled.div`
  width: 250px;
  ${calcHeight(250)};
  margin: 20px;

  /* iPad landscape */
  @media screen and (max-width: 1025px) {
    width: 192px;
    ${calcHeight(192)};
    margin: 3px;
  }

  /* iPhoneX landscape */
  @media screen and (max-width: 813px) {
    width: 188px;
    ${calcHeight(188)};
    margin: 3px;
  }

  /* iPad portrait */
  @media screen and (max-width: 769px) {
    width: 172px;
    ${calcHeight(172)};
    margin: 3px;
  }

  /* iPhone Plus landscape */
  @media screen and (max-width: 737px) {
    width: 165px;
    ${calcHeight(165)};
    margin: 3px;
  }

  /* Pixel2 landscape */
  @media screen and (max-width: 732px) {
    width: 232px;
    ${calcHeight(232)};
    margin: 10px;
  }

  /* iPhone6 landscape */
  @media screen and (max-width: 732px) {
    width: 200px;
    ${calcHeight(200)};
    margin: 10px;
  }

  /* iPhone Plus */
  @media screen and (max-width: 415px) {
    width: 190px;
    ${calcHeight(190)};
    margin: 3px;
  }

  /* Pixel (XL) */
  @media screen and (max-width: 412px) {
    width: 188px;
    ${calcHeight(188)};
    margin: 3px;
  }

  /* iPhone + Galaxy S5 */
  @media screen and (max-width: 376px) {
    width: 164px;
    ${calcHeight(164)};
    margin: 3px;
  }

  /* iPhone SE */
  @media screen and (max-width: 321px) {
    width: 250px;
    ${calcHeight(250)};
    margin: 20px;
  }
`;
