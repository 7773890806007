import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import Selectors from "../../Selectors";
import AppConfig from "../../Config";
import * as Widget from "../../Components/Widget";
import StoreInfoSection from "../../Components/StoreInfoSection";
import ProductGridItem from "../../Components/ProductGridItem";

class StoreDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: null,
      products: []
    };
  }

  componentDidMount() {
    let { appActions, storeId } = this.props;
    appActions
      .fetchStoreById(storeId)
      .then(store => {
        this.setState({ store });
      })
      .catch(err => {
        // TODO: handle for 404 and api failure
        console.warn(err);
      });

    appActions
      .fetchProductsByStoreId(storeId)
      .then(products => {
        this.setState({ products });
      })
      .catch(err => {
        // TODO: handle for 404 and api failure
        console.warn(err);
      });
  }

  render() {
    let { navActions } = this.props;
    let { store, products } = this.state;
    let productsArray = products.results;

    if (!store) {
      return (
        <Widget.Row css="padding: 20px;" align="center" justify="center">
          <Widget.Spinner />
        </Widget.Row>
      );
    }

    return (
      <Wrapper>
        <div className="store-section">
          <StoreInfoSection store={store} />
        </div>

        <div className="products-section">
          <h2>店家商品</h2>
          <div>
            {productsArray &&
              productsArray.map((product, i) => (
                <ProductGridItem
                  key={i}
                  item={product}
                  onClick={() => navActions.push(`/product/?id=${product.id}`)}
                  css="margin-right: 10px; margin-bottom: 10px;"
                />
              ))}
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;

  & h2 {
    margin-bottom: 70px;
    text-align: center;
    letter-spacing: 3.9px;
    font-size: 26px;
  }

  & > .store-section {
    margin: 100px 0px;
  }

  & > .products-section {
    margin-bottom: 100px;
    padding: 10px;
    & > div {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }

  ${AppConfig["Templates/StoreDetailPage"].css || ""}
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      storeId: Selectors.getQueryParams(ownProps).id,
      cartData: Selectors.getCartData(state)
    }),
    ActionCreator
  )(StoreDetailPage)
);
