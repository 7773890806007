import React, { Component } from "react";
import styled from "styled-components";
import Image from "../Image";
import VideoCarousel from "../VideoCarousel";

class StoreInfoSection extends Component {
  render() {
    let { store } = this.props;
    let imagesArray = store && store.images;

    return (
      <Wrapper>
        <div className="info">
          <Image
            background
            src={store.image}
            css={`
              border-radius: 50%;
              margin-bottom: 20px;
              width: 200px;
              height: 200px;
            `}
          />
          <h3>{store.name}</h3>
          <p>{this._limitWords(store.description)}</p>
        </div>

        {/* TODO: real images and videos url to render carousel */}
        <div className="carousel">
          <VideoCarousel />
        </div>
      </Wrapper>
    );
  }

  _limitWords = text => {
    let result = text;
    if (text.length > 60) {
      result = text.slice(0, 61);
      return result;
    }
    return text;
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 36px;
    max-width: 200px;
    width: 100%;

    & > h3 {
      margin-bottom: 20px;
      letter-spacing: 4.5px;
      font-size: 30px;
      color: #4e4e4e;
    }

    & > p {
      line-height: 24px;
      font-size: 14px;
      color: #4e4e4e;
    }
  }

  & > .carousel {
    margin-right: 6px;
    max-width: 733px;
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
    & > .info {
      margin-right: 0px;
      margin-bottom: 60px;
      max-width: 100%;

      & > p {
        max-width: 280px;
        text-align: center;
      }
    }

    & > .carousel {
      margin-right: 0px;
      margin-bottom: 6px;
      flex-direction: row;
    }
  }
`;

export default StoreInfoSection;
