import React, { Component } from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import ReactPlayer from "react-player";
import * as Icon from "../Icon";

const VideosUrl = [
  { url: "https://www.youtube.com/watch?v=1JFZvCh0EXI" },
  { url: "https://www.youtube.com/watch?v=l9qFLYZyhb8" },
  { url: "https://www.youtube.com/watch?v=j0HVSnqFJgg" }
];

class VideoCarousel extends Component {
  render() {
    return (
      <Wrapper>
        <Carousel
          renderCenterLeftControls={this._renderLeftArrow}
          renderCenterRightControls={this._renderRightArrow}
          renderBottomCenterControls={() => false}
        >
          {VideosUrl.map((d, i) => (
            <ReactPlayer
              key={"video" + i}
              url={d.url}
              width={"100%"}
              height={"447px"}
            />
          ))}
        </Carousel>
      </Wrapper>
    );
  }

  _renderLeftArrow = ({ previousSlide }) => (
    <IconContainer>
      <Icon.KeyboardArrowLeft
        color={"#4e4e4e"}
        style={{ width: 30, height: 30, cursor: "pointer" }}
        onClick={previousSlide}
      />
    </IconContainer>
  );

  _renderRightArrow = ({ nextSlide }) => (
    <IconContainer>
      <Icon.KeyboardArrowRight
        color={"#4e4e4e"}
        style={{ width: 30, height: 30, cursor: "pointer" }}
        onClick={nextSlide}
      />
    </IconContainer>
  );
}

const Wrapper = styled.div``;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.4);
`;

export default VideoCarousel;
